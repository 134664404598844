import React from 'react'

const RightTiltedArrow = ({
  size = '30px',
  color = 'var(--dls-brand-primary-color-500)',
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby="arrowRightTopIconTitle"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="square"
      strokeLinejoin="miter"
      fill="none"
      color={color}
    >
      <title id="arrowRightTopIconTitle">Arrow Right Top</title>
      <path d="M19 13V5h-8" />
      <path strokeLinecap="round" d="M19 5l-1 1" />
      <path d="M18 6L5 19" />
    </svg>
  )
}

export default RightTiltedArrow
