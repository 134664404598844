import React from 'react'

const ShoppingCart = ({
  size = '24',
  color = 'var(--dls-brand-primary-color-500)',
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.33325 8.33333H13.7756C14.8007 8.33333 15.3132 8.33333 15.7257 8.52183C16.0892 8.68794 16.3972 8.9551 16.613 9.29143C16.858 9.67309 16.9305 10.1805 17.0754 11.1953L19.0475 25M19.0475 25L23.4304 57.2142C23.9866 61.3022 24.2647 63.3462 25.242 64.8848C26.1032 66.2405 27.3378 67.3185 28.7972 67.989C30.4535 68.75 32.5164 68.75 36.642 68.75H72.2999C76.2272 68.75 78.1908 68.75 79.7956 68.0434C81.2105 67.4204 82.4243 66.416 83.3011 65.1426C84.2955 63.6984 84.6629 61.7694 85.3977 57.9115L90.9129 28.957C91.1715 27.5992 91.3008 26.9203 91.1134 26.3896C90.949 25.924 90.6246 25.532 90.198 25.2834C89.7118 25 89.0207 25 87.6384 25H19.0475ZM41.6666 87.5C41.6666 89.8012 39.8011 91.6667 37.4999 91.6667C35.1987 91.6667 33.3333 89.8012 33.3333 87.5C33.3333 85.1988 35.1987 83.3333 37.4999 83.3333C39.8011 83.3333 41.6666 85.1988 41.6666 87.5ZM74.9999 87.5C74.9999 89.8012 73.1344 91.6667 70.8332 91.6667C68.5321 91.6667 66.6666 89.8012 66.6666 87.5C66.6666 85.1988 68.5321 83.3333 70.8332 83.3333C73.1344 83.3333 74.9999 85.1988 74.9999 87.5Z"
        stroke={color}
        strokeWidth="5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default ShoppingCart
